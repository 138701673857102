<template>
<div class="members">
  <div>
    <span class="mr-2">内部成员</span> <span><strong>{{internalUserCnt}}</strong></span>
  </div>
  <div class="mt-4 d-flex justify-start align-center">
    <UserAvatar :fullName="itemOwner.employeeName" :name="itemOwner.employeeName.substring(0, 1)" width='60px' height='60px' :userId="itemOwner.id" />
    <div class="ml-4">
      <div>{{itemOwner.employeeName}}（负责人）</div>
      <div v-if="item.item_progress!=100">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <a>转交</a>
            </span>
          </template>
          <div class="pa-2" style="background-color:white;">
            <UserPicker @pick="changOwner" />
          </div>
        </v-menu>
      </div>
    </div>
  </div>
  <div class="mt-4 d-flex justify-start align-center">
    <v-menu offset-y v-if="item.item_progress!=100">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <div class="add-icon mr-4">+</div>
        </span>
      </template>
      <div class="pa-2" style="background-color:white;">
        <UserPicker @pick="pickInternalUser" />
      </div>
    </v-menu>
    <div class="d-flex justify-start align-center mr-4" v-for="user in internalUser" v-if="internalUser != []">
      <UserAvatar :name="user.employeeName.substring(0, 1)" width='60px' height='60px' :userId="user.id" />
      <div class="ml-4">
        <div>{{user.employeeName}}</div>
        <div @click="removeInternalUser(user.id)" style="cursor:pointer;" v-if="item.item_progress!=100">
          <a>删除</a>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <span class="mr-2">关注者</span> <span><strong>{{internalFollowerCnt}}</strong></span>
  </div>
  <div class="d-flex justify-start align-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <div class="add-icon mr-4">+</div>
        </span>
      </template>
      <div class="pa-2" style="background-color:white;">
        <UserPicker @pick="pickFollower" />
      </div>
    </v-menu>
    <div class="d-flex justify-start align-center mr-4" v-for="user in internalFollower" v-if="internalFollower != []">
      <UserAvatar :name="user.employeeName.substring(0, 1)" width='60px' height='60px' :userId="user.id" />
      <div class="ml-4">
        <div>{{user.employeeName}}</div>
        <div @click="removeFollowUser(user.id)" style="cursor:pointer;">
          <a>删除</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserAvatar from '@/components/common/UserAvatar.vue';
import {
  getUser
} from '@/utils/authUser.service';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'ItemMember',
  components: {
    UserAvatar,
    UserPicker: () => import('@/components/common/UserPicker.vue')
  },
  props: ['item'],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters("user", ["users"]),
    internalUser: function () {
      let listStr = this.item.item_participant.split(",");
      return listStr.map((item) => {
        let index = this.users.findIndex((o) => o.id == item);
        if (index != -1) return this.users[index];
      }).filter((o) => {
        return !!o;
      })
    },
    internalUserCnt: function () {
      let listStr = this.item.item_participant.split(",");
      return listStr.map((item) => {
        let index = this.users.findIndex((o) => o.id == item);
        if (index != -1) return item;
      }).filter((o) => {
        return !!o;
      }).length + 1;
    },
    internalFollower: function () {
      let listStr = this.item.item_followers.split(",");
      return listStr.map((item) => {
        let index = this.users.findIndex((o) => o.id == item);
        if (index != -1) return this.users[index];
      }).filter((o) => {
        return !!o;
      })
    },
    internalFollowerCnt: function () {
      let listStr = this.item.item_followers.split(",");
      return listStr.map((item) => {
        let index = this.users.findIndex((o) => o.id == item);
        if (index != -1) return this.users[index];
      }).filter((o) => {
        return !!o;
      }).length;
    },
    itemOwner: function () {
      let owner_index = this.users.findIndex(
        (o) => o.id == this.item.item_owner
      );
      return this.users[owner_index];
    }
  },
  methods: {
    ...mapActions('item', ['updateItemByField']),
    changOwner(param) {
      let payload = {
        item_id: this.item.item_id,
        item_owner: param.user.id
      }
      this.updateItemByField(payload)
    },
    pickInternalUser(param) {
      if (("," + this.item.item_participant + ",").search("," + param.user.id + ",") == -1) {
        let payload = {
          item_id: this.item.item_id,
          item_participant: (this.item.item_participant + "," + param.user.id).replace(/^[,]+|[,]+$/g, ""),
        }
        this.updateItemByField(payload)
      }
    },

    removeFollowUser(delUserId) {
      let re = new RegExp("^" + delUserId + ",|," + delUserId+ "|^"+delUserId+"$", "g");
      let payload = {
        item_id: this.item.item_id,
        item_followers: this.item.item_followers.replace(re, ""),
      };
      this.updateItemByField(payload);
    },
    removeInternalUser(delUserId) {
      let re = new RegExp("^" + delUserId + ",|," + delUserId+ "|^"+delUserId+"$", "g");
      let payload = {
        item_id: this.item.item_id,
        item_participant: this.item.item_participant.replace(re, ""),
      };
      this.updateItemByField(payload);
    },
    pickFollower(param) {
      if (("," + this.item.item_followers + ",").search("," + param.user.id + ",") == -1) {
        let payload = {
          item_id: this.item.item_id,
          item_followers: (this.item.item_followers + "," + param.user.id).replace(/^[,]+|[,]+$/g, ""),
        }
        this.updateItemByField(payload)
      }
    }
  }
}
</script>

<style scoped>
.add-icon {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 30px;
  box-shadow: none;
  background-color: #47C17F;
  text-align: center;
  color: #fff;
  padding: 0;
}
</style>
